import React, { useEffect, useRef, useState } from "react";

interface VideoComponentProps {
  vid: string;
  onEnd?: () => void; // Callback when the video ends
  className?: string;
}

export const VideoComponent: React.FC<VideoComponentProps> = ({
  vid,
  onEnd,
  className,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  // State to manage autoplay and the visibility of the play button
  const [shouldAutoplay, setShouldAutoplay] = useState(true);

  // Determine if the device is likely a mobile device based on window width
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Example breakpoint
      setShouldAutoplay(!isMobile);
    };

    // Call once to set initial state and add event listener for future resize events
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleVideoEnd = () => {
        if (onEnd) {
          const videoContainer = video.parentNode as HTMLElement;
          videoContainer.classList.add("fade-to-white");
          setTimeout(() => {
            onEnd();
          }, 2000);
        }
      };

      video.addEventListener("ended", handleVideoEnd);

      return () => {
        video.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, [onEnd]);

  // Handler for the play button click
  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <>
      <video
        ref={videoRef}
        className={className ?? "galleryImage"}
        autoPlay={shouldAutoplay}
        controls={!shouldAutoplay}
        muted
        loop>
        <source
          src={vid}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

import React from "react";
import { Col, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";

export const Podcast: React.FC = () => {
  const { theme } = useEloise();
  return (
    <Row className="my-5">
      <Row>
        {/* <Col
          lg={4}
          className="mx-auto py-2"
          style={{ border: "2px solid black" }}>
          <View>
            <Heading>
              Long-time manager of Wampum Hardware Company, Jay Elkin shares his
              story and the story of a hardware company that is in a leading
              position in their area of explosives by staying true and providing
              quality services over the years.
            </Heading>
          </View>
        </Col> */}
        <Col
          lg={8}
          className="mx-auto text-center">
          <iframe
            id="embedPlayer"
            src="https://embed.podcasts.apple.com/us/podcast/ep11-giving-the-customer-what-they-need-with-jay-elkin/id1669925989?i=1000616031717&amp;itsct=podcast_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto"
            height="175px"
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
            allow="autoplay *; encrypted-media *; clipboard-write"
            style={{
              width: "100%",

              overflow: "hidden",
              borderRadius: "10px",
              transform: "translateZ(0px)",
              animation: "2s ease 0s 6 normal none running loading-indicator",
              backgroundColor: theme.colors[0],
            }}></iframe>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col
          lg={8}
          className="mx-auto">
          <iframe
            id="embedPlayer"
            src="https://embed.podcasts.apple.com/us/podcast/precision-blasting-mastering-the-art-of-explosives/id1494235724?i=1000635763367&amp;itsct=podcast_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto"
            height="175px"
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
            allow="autoplay *; encrypted-media *; clipboard-write"
            style={{
              width: "100%",

              overflow: "hidden",
              borderRadius: "10px",
              transform: "translateZ(0px)",
              animation: "2s ease 0s 6 normal none running loading-indicator",
              backgroundColor: theme.colors[0],
            }}></iframe>
        </Col>{" "}
        {/* <Col
          lg={4}
          className="mx-auto py-2"
          style={{ border: "2px solid black" }}>
          <View>
            <Heading>
              In this week's episode join Aaron and Special Guest Travis
              Martzall of Wampum Hardware and Dyno Nobel and step into the
              explosive realm of drilling and blasting services. Discover the
              critical factors behind proper fragmentation and the art of
              efficient drilling techniques. From Tennessee's blasting practices
              to the use of draglines and dredging in the Boston Harbor, this
              episode uncovers the intricacies of precision blasting and its
              impact on the mining industry.
            </Heading>
          </View>
        </Col> */}
      </Row>
    </Row>
  );
};

import { mdiArrowDownThin } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import logoFull from "../../assets/logoFull.png";

import "../assets/vid.css";
export const Welcome: React.FC = () => {
  const { theme } = useEloise();
  return (
    <Row style={{ marginTop: "10vh" }}>
      <Col
        lg={5}
        xs={10}
        className="mx-auto text-center p-4">
        <img
          className="my-3"
          src={logoFull}
          style={{ width: "100%" }}></img>{" "}
        <Heading
          size={3}
          style={{ fontWeight: "bold" }}>
          Premium Explosives Supplier for Exceptional Drilling & Blasting
          Outcomes
        </Heading>
        <Row className="my-4">
          <Col
            lg={6}
            className="mx-auto text-center">
            <a
              style={{
                width: "100%",
                backgroundColor: theme.colors[1],
                border: "none",
                borderRadius: 0,
                fontSize: "1.3rem",
              }}
              href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=327C0ECE7C6877837B56F5A004534DF2&fromClientSide=true"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary">
              Join Our Team
            </a>
          </Col>
        </Row>
        <Icon
          style={{ marginTop: "16vh" }}
          path={mdiArrowDownThin}
          size={3}
        />
      </Col>
    </Row>
  );
};

import React from "react";
import { Heading } from "typed-component-gallery";
export const Footer: React.FC = () => {
  return (
    <footer className="sticky-footer">
      <div id="triangle-left">
        {" "}
        <a
          className="myLogoContainer"
          href="https://data26.solutions"
          target="_blank">
          {/* <Col lg={2}>
              <img
                src={dataLogo}
                className="myLogo"
              />
            </Col> */}

          <p
            style={{
              textDecoration: "none",

              color: "white",
              fontSize: ".5rem",
            }}>
            Web Design By:
          </p>
          <Heading
            align="left"
            style={{ marginTop: "-2vh" }}
            size={1}
            color="white">
            Data 26 Solutions
          </Heading>
        </a>
      </div>
      <div id="triangle-right"></div>
    </footer>
  );
};

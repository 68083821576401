import { Col, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import "../assets/vid.css";
const ContactInfo = () => {
  const { theme } = useEloise();
  return (
    <Row className="largeMargin">
      <Row className="mb-3">
        <Col
          lg={5}
          xs={10}
          className="mx-auto"
          style={{ borderBottom: theme.border }}>
          <Heading size={4}>Contact Us</Heading>
        </Col>
      </Row>
      <Col
        xs={10}
        style={{
          backgroundColor: theme.colors[0],
          color: "white",
          borderRadius: "5px",
        }}
        lg={10}
        className="mx-auto p-5 contact">
        <Row>
          <Col
            style={{ borderRight: "2px solid " + theme.colors[1] }}
            md={6}
            lg={3}
            className="mx-auto smallNoBorder">
            <h5>Office Hours:</h5>
            <p>Mon-Fri 7am-4pm EST</p>
            <h5>Contact:</h5>
            <p>
              Office:
              <a
                style={{
                  color: "white",
                  marginLeft: "6px",
                  textDecorationSkipInk: "none",
                }}
                href="tel:+17243364501">
                (724) 336-4501
              </a>
            </p>
            <p>
              Fax:
              <a
                style={{
                  color: "white",
                  marginLeft: "6px",
                  textDecorationSkipInk: "none",
                }}
                href="tel:+17243363818">
                (724) 336-3818
              </a>
            </p>
          </Col>
          <Col
            md={6}
            lg={3}
            className="mx-auto">
            <h5>Address:</h5>
            <p>
              636 Paden Road
              <br />
              New Galilee, PA 16141
            </p>
          </Col>
          <Col
            style={{ borderLeft: "2px solid " + theme.colors[1] }}
            md={12}
            lg={3}
            className="mx-auto smallNoBorder">
            <p>
              <h5>General Inquiries:</h5>
              <a
                style={{ color: "white" }}
                href="mailto:info@wampumhardware.com">
                info@wampumhardware.com
              </a>
            </p>
            <h5>HR and Job Inquiries:</h5>
            <p>
              Joanna Rich
              <br />
              Human Resources Administrator
              <br />
              <a
                style={{ color: "white" }}
                href="tel:+17243368238">
                (724) 336-8238
              </a>
              <br />
              <a
                style={{ color: "white" }}
                href="mailto:jrich@wampumhardware.com">
                jrich@wampumhardware.com
              </a>
            </p>
          </Col>
        </Row>
        <Row className="my-3">
          <Col
            lg={6}
            className="mx-auto text-center">
            <a
              style={{
                backgroundColor: theme.colors[1],
                border: "none",
                borderRadius: 0,
              }}
              href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=327C0ECE7C6877837B56F5A004534DF2&fromClientSide=true"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary">
              Join Our Team
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContactInfo;

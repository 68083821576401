import { useEffect, useRef, useState } from "react";
import "../assets/vid.css";

const timelineEvents = [
  {
    year: 1904,
    description:
      "Dr. Charles Davis and brother, Francis purchase Wampum Hardware",
  },
  {
    year: 1910,
    description:
      '"Dimple" Davis, 23, saves the town of Wampum from destruction by fire',
  },
  {
    year: 1958,
    description: "Lynn Davis moves family business to New Galilee, PA",
  },
  {
    year: 1964,
    description: "Lynn Davis passes away, and son Jerry, 23, takes the reins",
  },
  { year: 1966, description: "DuPont withdraws its contract from Wampum" },
  {
    year: 1967,
    description:
      "Jerry and his brother Paul begin providing bulk explosives and complete blasting services",
  },
  {
    year: 1977,
    description: "Acquired Cambridge, OH facility from Atlas Powder",
  },
  { year: 1982, description: "Formed Northern Ohio Explosives" },
  { year: 1987, description: "Joint venture with Dyno Nobel is formed" },
  {
    year: 1988,
    description:
      "1988 acquired Somerset and Edinberg, PA locations from Independent Explosives of Scranton, PA. Jay Elkin joins Wampum Hardware Co.",
  },
  { year: 1990, description: "Acquired DJ Maurer" },
  { year: 1996, description: "Acquired Keystone Explosive (ETI)" },
  { year: 1999, description: "Acquired Amos L. Dolby" },
  {
    year: 2004,
    description:
      "Acquired Pennville, IN & Mylan, Michigan sites from Dyno Nobel.",
  },
  {
    year: 2014,
    description:
      "Acquired the Clarksburg, WV Dyno Nobel Site – which is now our Fairmont, WV  Location.",
  },
  {
    year: "Present",
    description: "Operating in 5 States With Over 200 Employees",
  },

  // Add more events as needed
];

export const Timeline: React.FC = () => {
  const [shadow, setShadow] = useState<string>("");
  const timelineRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const element = timelineRef.current;
    if (element) {
      const atTop = element.scrollTop === 0;
      // Allow a small threshold for detecting the bottom, to account for any rounding errors
      const atBottom =
        Math.abs(
          element.scrollHeight - element.scrollTop - element.clientHeight
        ) < 1;

      if (atTop) {
        setShadow(atBottom ? "" : "shadow-bottom");
      } else if (atBottom) {
        setShadow("shadow-top");
      } else {
        setShadow("shadow-both");
      }
    }
  };

  useEffect(() => {
    const element = timelineRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);

      // Perform an initial check in case the component starts in a scrolled state
      handleScroll();

      // Cleanup on component unmount
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, []); // The empty array means this effect runs once on mount and cleanup on unmount

  return (
    <div
      className={`outer mx-auto noScrollBar timeline ${shadow}`}
      ref={timelineRef}>
      {timelineEvents.map((event, index) => (
        <div
          key={index}
          className="card">
          <div className="info">
            <h3 className="title">{event.year.toString()}</h3>
            <p>{event.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

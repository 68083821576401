import React from "react";
import { Services } from "../widgets";

export const ServicesPage: React.FC = () => {
  return (
    <>
      <Services />
    </>
  );
};

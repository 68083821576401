import React from "react";
import { About } from "../widgets";

export const AboutPage: React.FC = () => {
  return (
    <>
      <About />
    </>
  );
};

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import newTruck from "../../assets/bulkTrucks2.png";
import oldPic1 from "../../assets/oldPic1.jpg";
import oldPic2 from "../../assets/oldPic2.jpg";
import pepes from "../../assets/pepes.jpg";

import "../assets/vid.css";
import { Timeline } from "../widgets/timeLine";
export const History: React.FC = () => {
  const { theme } = useEloise();
  return (
    <Row className="mt-5">
      <Col
        lg={{ span: 3, order: 0 }}
        xs={{ order: 2, span: 10 }}
        className="smallAuto mt-4">
        <img
          className="my-3"
          src={newTruck}
          style={{ width: "100%" }}
        />{" "}
        <img
          src={oldPic1}
          style={{ width: "100%" }}
          alt="Clasic Truck in Black and White"
        />
      </Col>
      <Col
        lg={6}
        className="mx-auto ">
        <Row className="my-3">
          <Col
            lg={5}
            className="mx-auto mb-5"
            style={{ borderBottom: theme.border }}>
            <Heading size={4}>History</Heading>
            <Heading size={1}>Scroll the Timeline to Learn More</Heading>
          </Col>
        </Row>
        <Col
          xs={{ order: 0, span: 10 }}
          lg={{ span: 9, order: 1 }}
          className="mx-auto ">
          <Timeline />
        </Col>
      </Col>
      <Col
        lg={{ order: 2, span: 3 }}
        xs={{ order: 1, span: 10 }}
        className="d-flex align-items-center smallAuto mt-4"
        style={{ height: "100%" }}>
        <div className="w-100 text-center">
          <img
            src={pepes}
            style={{ width: "100%" }}
            alt="Clasic Truck in Black and White"
          />
          <img
            className="mt-4 mx-auto"
            src={oldPic2}
            style={{ width: "75%" }}
            alt="Founders"
          />
        </div>
      </Col>
    </Row>
  );
};

import { SiteConfig } from "typed-component-gallery";
import annLogo from "../assets/annLogo.png";
import logo from "../assets/ogLogo.png";
import {
  AboutPage,
  Careers,
  Dash,
  Locations,
  Media,
  Photos,
  Saftey,
  ServicesPage,
} from "../components/pages";

export const site: SiteConfig = {
  id: "wampum",
  name: "Wampum Hardware Co.",
  logo: annLogo,
  inverseLogo: logo,
  api: "http://localhost:5001",
  defaultMode: "user",
  headerTrans: false,
  noAuth: true,
  peopleConfig: [],
  sideWidget: [],
  logoWidth: 200,
  pages: [
    {
      name: "/",
      component: <Dash />,
    },
    {
      name: "Home",
      component: <Dash />,
    },
    { name: "About Us", component: <AboutPage />, url: "about-us" },
    {
      name: "Career Opportunities",
      component: <Careers />,
      url: "career-opportunities",
    },
    {
      name: "Locations",
      component: <Locations />,
    },
    { name: "Media", component: <Media /> },
    {
      name: "Photos and Videos",
      component: <Photos />,
    },
    {
      name: "Safety",
      component: <Saftey />,
    },
    { name: "Services", component: <ServicesPage /> },
  ],
  eloiseConfig: {
    endPoint: "http://127.0.0.1:5001/eloiselife-c5cf6/us-central1/musicGpt",
    chatLog: "vibez/chat",
    initMessage:
      "Hey there, Describe the music you are seeking and I will see what I can do. ",
  },
  hostingSite: "vibez",
};

import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { Eloise } from "typed-component-gallery";
import { fb } from "./config/fb";
import { site } from "./config/site";
import { themeConfig } from "./config/theme";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Eloise
    theme={themeConfig}
    siteConfig={site}
    fireBaseConfig={fb}
  />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

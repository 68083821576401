import { Theme } from "typed-component-gallery";

export const themeConfig: Theme = {
  colors: [
    "hsla(198, 29%, 16%,1)",
    "hsla(360, 100%, 50%, 1)",
    "hsla(193, 8%, 58%, 1)",
    "hsla(46, 82%, 67%, 1)",
    "hsla(74, 65%, 80%, 1)",
  ],

  white: "hsla(90, 60%, 96%, 1)",
  font: "Lato",
  headerStyle: {
    width: "100%",
    position: "fixed",
    marginBottom: "20px",
  },
  noHeaderLogo: false,
  noHeaderText: true,
  fontSize: "1.25px",
  borderRadius: "5px",
  border: "1px solid black",
  mode: "light",
  heading: {
    color: "hsla(216, 96%, 10%,1)",
  },

  input: {
    border: true,
    rounded: true,
    extLabel: true,
  },
};

import { Col, Container, Row } from "react-bootstrap";

const ServicesList = () => {
  const columnOneItems = [
    "Technical Services",
    "- 2D and 3D Profiling",
    "- Bore Tracking",
    "- Blast Analysis",
    "- Vibration Monitoring",
    "- Signature Hole Analysis",
    "Industrial Minerals",
    "- Coal Mining",
    "- Surface Applications",
    "- Underground Applications",
    "- Shaft, Slope Applications and Face up",
    "Aggregate Mining",
    "- Surface Applications",
    "- Underground Applications",
    "- Shaft and Face up Construction Blasting",
  ];

  const columnTwoItems = [
    "Construction Drilling & Blasting",
    "- Site Development",
    "_ Commercial",
    "_ Industrial",
    "_ Residential and Energy",
    "Seismic Exploration",
    "- Blaster Services",
    "- Delivery Services",
    "- Magazine Storage",
    "- Site Security",
    "Compliance Regulation",
    "- Blasting Permitting",
    "- Explosive Storage Permitting",
    "- DEP, ATF and Fire Marshall",

    "Pipeline & Utility Trenching",
    "Demolition Support",
  ];

  // Function to render items and subheaders
  const renderItemOrSubheader = (item: any, index: number) => {
    // Check if the item starts with a dash "-"
    if (item.startsWith("-")) {
      return (
        <div
          key={index}
          className="list-group-subheader">
          {item}
        </div>
      );
    } else if (item.startsWith("_")) {
      // Check if the item starts with an underscore "_"
      // Modify the item to start with a dash instead of an underscore for display
      const modifiedItem = "-" + item.substring(1);
      return (
        // Render the item as more deeply nested. Adjust the className as needed for your styling.
        <div
          key={index}
          className="nested-item my-2">
          {modifiedItem}
        </div>
      );
    } else {
      return (
        <li
          key={index}
          className="my-2">
          {item}
        </li>
      );
    }
  };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <ul className="custom-list">
            {columnOneItems.map(renderItemOrSubheader)}
          </ul>
        </Col>
        <Col md={6}>
          <ul className="custom-list">
            {columnTwoItems.map(renderItemOrSubheader)}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default ServicesList;

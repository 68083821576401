import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "typed-component-gallery";
import { Podcast } from "../widgets/podcast";

export const Media: React.FC = () => {
  return (
    <Container
      fluid
      className="largeMargin">
      <Row className="my-4">
        <Col
          lg={5}
          className="mx-auto"
          style={{ borderBottom: "2px solid black" }}>
          <Heading size={4}>Media</Heading>
        </Col>
      </Row>
      <Row className="my-4 p-0 g-0">
        <Col
          lg={9}
          className="mx-auto text-center">
          <iframe
            style={{ width: "100%", height: "500px" }}
            src="https://www.youtube.com/embed/jWaGptRynxw?si=PVjmyfwc6ahzVh3l"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </Col>
      </Row>

      <Podcast />
      <Row className="my-4 p-0 g-0">
        <Col
          lg={9}
          className="mx-auto text-center">
          <iframe
            style={{ width: "100%", height: "500px" }}
            src="      https://www.youtube.com/embed/4WjhG-xLOIM?si=xRj67WSKy_ydOQE7
            "
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </Col>
      </Row>
    </Container>
  );
};

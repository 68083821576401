import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import blaster from "../../assets/bulkTrucksM.png";
import hanging from "../../assets/hanging.jpg";
import drill from "../../assets/newDrill.jpg";
import blastVidNew from "../../assets/vidBlast.mp4";
import "../assets/vid.css";
import ServicesList from "../widgets/servicesList";
import { VideoComponent } from "./vid";
const blastVids = [blastVidNew];

export const Services: React.FC = () => {
  const { theme } = useEloise();
  return (
    <Container
      fluid
      className="largeMargin">
      <Row className="my-3">
        <Col
          style={{
            borderRight: `1px solid ${theme.colors[1]}`,
            borderBottom: `1px solid ${theme.colors[1]}`,
          }}
          lg={7}
          xs={10}
          className="mx-auto triangle-corner-col verticalSmall">
          <Row className="mb-3">
            <Col
              lg={5}
              className="mx-auto"
              style={{ borderBottom: theme.border }}>
              <Heading size={2}>Services We Offer</Heading>
            </Col>
          </Row>
          <ServicesList />
        </Col>
        <Col
          lg={5}
          className="mx-auto">
          <Row className="my-3">
            <Col
              className="smallAuto text-center mx-auto"
              lg={9}
              xs={10}>
              <img
                src={blaster}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>

          <Row className="mt-5">
            <Col
              className="smallAuto text-center"
              lg={6}
              xs={10}>
              <img
                src={drill}
                style={{ width: "80%" }}
              />
            </Col>
            <Col
              className="smallAuto text-center"
              lg={6}
              xs={10}>
              <img
                src={hanging}
                style={{ width: "80%" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4">
        {blastVids.map((vid) => (
          <Col
            lg={11}
            xs={11}
            className="mx-auto">
            <VideoComponent
              vid={vid}
              className="nonGalleryImage"
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "typed-component-gallery";
import gallery2 from "../../assets/angle.png";
import blastVid2 from "../../assets/blastVid2.mp4";
import blastVid6 from "../../assets/blastVid21.mp4";
import blastVid3 from "../../assets/blastVid3.mp4";
import blastVid4 from "../../assets/blastVid4.mp4";
import blastVid5 from "../../assets/blastVid5.mp4";
import gallery14 from "../../assets/bulkTrucks2.png";
import gallery13 from "../../assets/bulkTrucksM.png";
import gallery1 from "../../assets/gallery1.png";
import gallery11 from "../../assets/gallery11.jpg";
import gallery4 from "../../assets/gallery4.jpg";
import gallery3 from "../../assets/gallery7.jpg";
import gallery5 from "../../assets/newDrill.jpg";
import gallery10 from "../../assets/plant.jpg";
import gallery12 from "../../assets/trucks2.png";

import { VideoComponent } from "../widgets";
const blastVids = [blastVid2, blastVid3, blastVid4, blastVid5, blastVid6];

const images = [
  { img: gallery4, text: "Rendering" },
  { img: gallery1, text: "Busy In the Hole" },
  { img: gallery2, text: "Ready to Blast" },
  { img: gallery3, text: "Well Controlled Blast" },
  { img: gallery5, text: "Community Involvment" },
  { img: gallery10, text: "Community Involvment" },
  { img: gallery11, text: "Community Involvment" },
  { img: gallery12, text: "Community Involvment" },
  { img: gallery13, text: "Community Involvment" },
  { img: gallery14, text: "Community Involvment" },
  // { img: gallery13, text: "Community Involvment" },
];

export const Photos: React.FC = () => {
  return (
    <Container
      fluid
      className="largeMargin">
      <Row className="my-4">
        <Col
          lg={5}
          className="mx-auto"
          style={{ borderBottom: "2px solid black" }}>
          <Heading size={4}>Photo Gallery</Heading>
        </Col>
      </Row>
      {/* <Row>
        {images.map((image) => (
          <Col
            lg={4}
            className="mx-auto">
            <Row>
              {image.img && (
                <img
                  src={image.img}
                  style={{ width: "100%" }}
                />
              )}
            </Row>
            <Row>
              <Heading size={3}>{image.text}</Heading>
            </Row>
          </Col>
        ))}
      </Row> */}

      <Row>
        <Col
          lg={10}
          className="mx-auto">
          <Row>
            {images.map((image) => (
              <Col
                lg={4}
                className="verticalSmall">
                {image.img && (
                  <img
                    className="galleryImage"
                    src={image.img}
                    alt={image.text}
                  />
                )}
              </Col>
            ))}
            {blastVids.map((vid) => (
              <Col
                lg={4}
                className="verticalSmall">
                <VideoComponent vid={vid} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

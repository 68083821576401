import { mdiHardHat } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading, View, useEloise } from "typed-component-gallery";
import safeLogo1 from "../../assets/safetyLogo4.png";
import safeLogo2 from "../../assets/safetyLogo5.png";
import safeLogo3 from "../../assets/safetyLogo6.png";
import safeLogo4 from "../../assets/safetyLogo7.png";

import InformationAccordion from "../widgets/safteyAcc";

const rawText = `CULTURE: Safety is a core value at Wampum Hardware. Our guiding principle, for well
over 100 years, is making sure that every employee returns home safe and healthy at
the end of the day. Working safely enables Wampum Hardware to continue doing what
we do best; producing results, responsibly and efficiently.
SAFETY: We are fully committed to the health and protection of each and every one of
our employees and customers. We actively promote safety and accident prevention
through on-going compliance training, practices and programs, resulting in a proven
safety record. We are extremely proud of our safety history and believe that in our
industry there is nothing more important than a strong safety culture. With our own in-
house trainers and state of the art training facility, we exceed MSHA and OSHA
requirements and are affiliated with other safety and OQ organizations, including
ISNetworld, BROWZ, and Axiom.
ENVIRONMENT: We work closely and diligently with customers, landowners and
government agencies to minimize the impact of any and all of our services on the
environment. Not only are we a leader in our industry, we strive to be a leader and a
steward for the environment.
Wampum Hardware has been in business since 1904, and under the guiding hand of the
Davis Family will continue to be a family first company for generations to come.`;

export const Saftey: React.FC = () => {
  const { theme } = useEloise();
  return (
    <Container
      fluid
      className="largeMargin">
      <Row className="mt-4">
        <Col
          lg={5}
          xs={10}
          className="mx-auto"
          style={{ borderBottom: "2px solid black" }}>
          <Row>
            <Col lg={3}></Col>
            <Col
              lg={3}
              className="mx-auto text-center">
              <Icon
                path={mdiHardHat}
                size={4}
                color={theme.colors[0]}
              />
            </Col>
            <Col
              lg={3}
              className="mx-auto">
              <View>
                <Heading size={4}>Safety</Heading>
              </View>
            </Col>
            <Col lg={3}></Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          lg={6}
          xs={11}
          className="mx-auto">
          <InformationAccordion />
        </Col>
        <Col
          lg={5}
          className="text-center mt-5">
          <Row>
            <Col
              style={{
                border: "2px solid " + theme.colors[0],
                color: "white",
              }}
              lg={11}
              xs={10}
              className="mx-auto p-5 contact">
              <Row>
                <Col
                  lg={6}
                  className="mx-auto">
                  <View>
                    <Heading
                      align="left"
                      size={3}>
                      Subscribers Of
                    </Heading>
                  </View>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <img
                    src={safeLogo1}
                    width={150}></img>
                </Col>
                <Col lg={5}>
                  <img
                    src={safeLogo2}
                    width={150}></img>
                </Col>
                <Col lg={5}>
                  <img
                    src={safeLogo4}
                    width={150}></img>
                </Col>
                <Col lg={5}>
                  <img
                    src={safeLogo3}
                    width={150}></img>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="my-5">
            <Col
              style={{
                border: "2px solid " + theme.colors[0],
                color: "white",
              }}
              lg={11}
              xs={10}
              className="mx-auto p-3 py-4 contact">
              <Row>
                <Col
                  lg={11}
                  style={{
                    color: "black",
                    fontSize: "1.2rem",
                    textAlign: "left",
                  }}>
                  <span>
                    If you have questions about explosives we will be using in
                    your area you will find the following link very
                    informative:&nbsp;
                  </span>
                  <a href="http://explosives.org/">http://explosives.org/</a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="my-5">
            <Col
              style={{
                border: "2px solid " + theme.colors[0],
                color: "white",
              }}
              lg={11}
              xs={10}
              className="mx-auto p-3 py-4 contact">
              <Row>
                <Col
                  lg={11}
                  style={{
                    color: "black",
                    fontSize: "1.2rem",
                    textAlign: "left",
                  }}>
                  <span>
                    Need an MSDS (SDS) sheet for products we will be using on
                    your property?
                  </span>
                  <a href="http://www.dynonobel.com/resource-hub/products">
                    http://www.dynonobel.com/resource-hub/products
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-5">
        <Col
          lg={5}
          xs={8}
          className="mx-auto"
          style={{ borderBottom: theme.border }}>
          <Heading size={4}>Safety Personnel</Heading>
        </Col>
      </Row>

      <Row className="my-5">
        <Col
          style={{
            backgroundColor: theme.colors[0],
            color: "white",
            borderRadius: "5px",
          }}
          lg={10}
          xs={10}
          className=" mx-auto p-5 contact">
          <Row>
            <Col
              lg={3}
              className=" mx-auto text-center">
              {" "}
              {/* Use the custom class here */}
              <h2>Marvin Lee</h2>
              <p>Safety Director</p>
              <a
                style={{ color: "white" }}
                href="mailto:mlee@wampumhardware.com">
                mlee@wampumhardware.com
              </a>
            </Col>
            <Col
              lg={3}
              className="mx-auto text-center">
              {" "}
              {/* Apply vertical margin in the responsive class if needed */}
              <h2>Grant Ahrens</h2>
              <p>Safety Manager</p>
              <a
                style={{ color: "white" }}
                href="mailto:gahrens@wampumhardware.com ">
                gahrens@wampumhardware.com
              </a>
            </Col>
            <Col
              lg={3}
              className="mx-auto text-center">
              {" "}
              {/* Apply vertical margin in the responsive class if needed */}
              <h2>Frank Gagliardo</h2>
              <p>Safety Manager</p>
              <a
                style={{ color: "white" }}
                href="mailto:Fgagliardo@wampumhardware.com">
                fgagliardo@wampumhardware.com
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

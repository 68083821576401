import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import "../assets/vid.css";

const locations = [
  {
    name: "Wampum Hardware Co. - New Galilee",
    address: "636 Paden Road, New Galilee, PA 16141",
    lat: 40.91787,
    lng: -80.42471,
    phone: "724-336-4501",
  },
  {
    name: "Armstrong Explosives Co. - Grove City",
    address: "130 Weston Road, Grove City, PA 16127",
    lat: 41.22408,
    lng: -79.97392,
    phone: "724-786-9463",
  },
  {
    name: "Armstrong Explosives Co. - Kittanning",
    address: "385 White Road, Kittanning, PA 16201",
    lat: 40.82918,
    lng: -79.46163,
    phone: "724-545-2020",
  },
  {
    name: "Wampum Hardware Co. - Fairmont",
    address: "294 Boulder Lane, Fairmont, WV 26554",
    lat: 39.4214,
    lng: -80.22102,
    phone: "304-363-0055",
  },
  {
    name: "Wampum Hardware Co. - Salesville/Gibson",
    address: "60711 Dynamite Rd, Salesville, OH 43778",
    lat: 39.97577,
    lng: -81.42885,
    phone: "740-685-2585",
  },
  {
    name: "Wampum Hardware Co. - Stoystown/Somerset",
    address: "533 Old Lincoln Highway, Stoystown, PA 15563",
    lat: 40.08419,
    lng: -78.92478,
    phone: "814-893-5470",
  },
  {
    name: "Northern Ohio Explosives - Forest",
    address: "17507 Township Road 50, Forest, OH 45843",
    lat: 40.76047,
    lng: -83.54773,
    phone: "419-273-2542",
  },
  {
    name: "Wampum Hardware Co. - Pennville",
    address: "7860 W. 400 N, Pennville, IN 47369",
    lat: 40.4956,
    lng: -85.13339,
    phone: "260-731-4431",
  },
  {
    name: "Wampum Hardware Co. - Philipsburg",
    address: "2194 Port Matilda Highway, Philipsburg, PA 16866",
    lat: 40.8887,
    lng: -78.19059,
    phone: "814-342-2310",
  },
];

export const Locations = () => {
  const { theme } = useEloise();
  const mapContainerStyle = {
    width: "100%",
    height: "600px", // Adjust height as needed
  };

  const center = {
    lat: 40.91787, // Example central point; adjust as needed
    lng: -80.42471,
  };

  const [zoom, setZoom] = useState(7);

  // Determine if the device is likely a mobile device based on window width
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Example breakpoint
      setZoom(isMobile ? 5 : 7);
    };

    // Call once to set initial state and add event listener for future resize events
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container
      fluid
      className="largeMargin">
      <Row className="my-5">
        <Col
          lg={6}
          className="mx-auto"
          style={{ borderBottom: "2px solid black" }}>
          <Heading size={4}>Locations</Heading>
        </Col>
      </Row>
      <Row className="my-5">
        <Col
          lg={10}
          className="mx-auto">
          <LoadScript googleMapsApiKey="AIzaSyAQlTqjngmh56UBHdmcI8oAhjEG9tj0uio">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={zoom}>
              {locations.map((location) => (
                <Marker
                  key={location.name}
                  position={{ lat: location.lat, lng: location.lng }}
                  title={location.name}
                />
              ))}
            </GoogleMap>
          </LoadScript>
        </Col>
      </Row>
      <Row className="my-5">
        <Col
          lg={10}
          className="mx-auto">
          <Row>
            {locations.map((location, index) => (
              <Col
                lg={3}
                key={index}
                className="location-info">
                <h5
                  style={{ color: theme.colors[1] }}
                  className="mb-3">
                  {location.name}
                </h5>
                <p>{location.address}</p>
                <p>
                  Phone:{" "}
                  <a
                    style={{ color: "black" }}
                    href={`tel:${location.phone}`}>
                    {location.phone}
                  </a>
                </p>
              </Col>
            ))}
          </Row>{" "}
        </Col>
      </Row>
    </Container>
  );
};

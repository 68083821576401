import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";

const InformationAccordion = () => {
  const { theme } = useEloise();

  return (
    <Container>
      <Row className="my-5">
        <Col lg={12}>
          <Accordion
            defaultActiveKey={["0", "1", "2"]}
            alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Culture</Accordion.Header>
              <Accordion.Body>
                Safety is a core value at Wampum Hardware. Our guiding
                principle, for well over 100 years, is making sure that every
                employee returns home safe and healthy at the end of the day.
                Working safely enables Wampum Hardware to continue doing what we
                do best; producing results, responsibly and efficiently.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Safety</Accordion.Header>
              <Accordion.Body>
                We are fully committed to the health and protection of every one
                of our employees and customers. We actively promote safety and
                accident prevention through on-going compliance training,
                practices, and programs, resulting in a proven safety record. We
                are extremely proud of our safety history and believe that in
                our industry there is nothing more important than a strong
                safety culture. With our own in- house trainers and state of the
                art training facility, we exceed MSHA and OSHA requirements and
                are affiliated with other safety and OQ organizations, including
                ISNetworld, Veriforce, Avetta, GRMS, Axiom and TPS.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Environment</Accordion.Header>
              <Accordion.Body>
                We work closely and diligently with customers, landowners, and
                government agencies to minimize the impact of all our services
                on the environment. Not only are we a leader in our industry,
                but we also strive to be a leader and a steward for the
                environment. Wampum Hardware has been in business since 1904,
                and under the guiding hand of the Davis Family will continue to
                be a family first company for generations to come.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default InformationAccordion;

import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import newTrucks from "../../assets/loadingHoles.jpg";
export const Careers: React.FC = () => {
  const { theme } = useEloise();
  const boxes = [
    {
      title: "Healthcare",
      text: "Wampum Offers comprehensive medical (including a prescription drug benefit) and dental care programs that provide a wide variety of coverage levels to meet individual needs. These programs contain access to care through a network of providers.",
    },
    {
      title: "Paid Time Off",
      text: "Wampum recognizes that everyone needs time-off to handle personal and other important matters or just for rest and relaxation. Therefore, we offer a variety of paid time-off including: personal time off, certain national holidays as well as bereavement and jury duty.",
    },
    {
      title: "Retirement Plans",
      list: [
        "The company provides an annual substantial contribution to a Profit Sharing plan for the employee.",
        "To help your retirement savings Wampum also offers a 401(k) Plan.",
        "You can invest your money in a wide range of investment options for either plan to meet your financial goals.",
        "You may also rollover eligible distributions from your former employers plan.",
      ],
    },
    {
      title: "Other Benefits",
      list: [
        "Company paid short term disability plan",
        "Company paid life insurance",
        "Vision discount program",
        "Discounts on certain Verizon and ATT mobile plans",
      ],
    },
  ];
  return (
    <Container
      fluid
      className="largeMargin">
      <Row>
        <Col
          lg={5}
          xs={11}
          className="mx-auto"
          style={{ borderBottom: "2px solid black" }}>
          <Heading size={4}>Career Opportunities</Heading>
        </Col>
      </Row>
      <Row className="my-5">
        <Col
          lg={{ span: 5, order: 0 }}
          xs={{ span: 11, order: 0 }}
          className="mx-auto">
          <Row>
            <Heading size={4}>Join Our Team</Heading>
          </Row>
          <Row>
            <Col
              lg={12}
              style={{ border: "2px solid black" }}>
              <Row className="my-4 px-3">
                <Heading
                  size={2}
                  align="left">
                  Employees of Wampum Hardware are among the best managers,
                  drillers, blasters, drivers and administrators in the field of
                  blasting and drilling. They take pride in their ability to
                  bring together resources, information, and services in ways
                  that benefit our clients.
                </Heading>
              </Row>
              <Row className="my-4 px-3">
                <Heading
                  size={2}
                  align="left">
                  Wampum offers outstanding employment opportunities and is
                  always looking for outstanding people to join its team. WHC
                  has approximately 200 employees in Pennsylvania, Ohio,
                  Indiana, Michigan, and West Virginia. In addition, we offer a
                  competitive compensation package.
                </Heading>
              </Row>
            </Col>
          </Row>
          <Row className="my-4 text-center">
            <Col
              lg={6}
              className="mx-auto">
              <img
                className="p-0"
                src={newTrucks}
                style={{ width: "100%" }}
              />
            </Col>
            <Col
              lg={6}
              className="mx-auto">
              <iframe
                style={{ width: "100%", height: "400px" }}
                src="      https://www.youtube.com/embed/ov6Xdwe6XfM
            "
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </Col>
          </Row>
          <Row className="my-4">
            <Col
              lg={12}
              style={{ border: "2px solid black" }}>
              <Row className="my-4 px-3">
                <Heading
                  size={2}
                  align="left">
                  If you possess the skills and qualifications we are seeking we
                  encourage you to apply. For complete listing of current
                  openings and details in relation to the job and to apply:
                </Heading>
              </Row>
              <Row className="my-4 px-3">
                <Col
                  lg={8}
                  className="mx-auto text-center">
                  <a
                    style={{
                      fontSize: "1.2rem",
                      backgroundColor: theme.colors[1],
                      border: "none",
                      borderRadius: 0,
                      width: "100%",
                    }}
                    href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=327C0ECE7C6877837B56F5A004534DF2&fromClientSide=true"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary">
                    View Listings
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="my-4">
            <Col
              lg={12}
              style={{ border: "2px solid black" }}>
              <Row className="my-4 px-3">
                <Heading
                  size={2}
                  align="left">
                  Wampum Hardware Co. is an equal opportunity employer that
                  complies with the laws and regulations set forth in the:
                </Heading>
              </Row>

              <Row className="my-4 px-3">
                <Col
                  lg={8}
                  className="mx-auto text-center">
                  <a
                    style={{
                      fontSize: "1.2rem",
                      backgroundColor: theme.colors[0],
                      border: "none",
                      borderRadius: 0,
                      width: "100%",
                    }}
                    href="https://www.dol.gov/sites/dolgov/files/ofccp/regs/compliance/posters/pdf/OFCCP_EEO_Supplement_Final_JRF_QA_508c.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary">
                    EEO is The Law Poster Supplement
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          lg={{ span: 5, order: 1 }}
          xs={{ span: 11, order: 1 }}
          className="mx-auto">
          <Row>
            <Heading size={4}>Benefits</Heading>
          </Row>
          <Accordion
            defaultActiveKey={["0", "1", "2", "3"]}
            alwaysOpen>
            {boxes.map((box, index) => (
              <Accordion.Item
                eventKey={`${index}`}
                key={index}>
                <Accordion.Header>{box.title}</Accordion.Header>
                <Accordion.Body>
                  {box.text && <p>{box.text}</p>}
                  {box.list && (
                    <ul>
                      {box.list.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <Row className="mt-5">
            <Col
              lg={11}
              className="mx-auto"
              style={{ border: "2px solid black" }}>
              <Row className="my-4 px-3">
                <Heading
                  size={2}
                  align="left">
                  Wampum Hardware Co. does not discriminate against applicants
                  or employees on the basis of race, color, religion, creed,
                  national origin, ancestry, disability that can be reasonably
                  accommodated without undue hardship, sex, sexual orientation,
                  gender identity, age, citizenship, marital or veteran status,
                  or any other legally protected status.
                </Heading>
              </Row>
            </Col>
          </Row>
          {/* <Row className="my-4 p-0 g-0">
            <Col
              lg={12}
              className="mx-auto text-center">
              <iframe
                style={{ width: "100%", height: "400px" }}
                src="      https://www.youtube.com/embed/ov6Xdwe6XfM
            "
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </Container>
  );
};

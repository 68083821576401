import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Heading, useEloise } from "typed-component-gallery";
import dyno from "../../assets/dyno.png";
import isee from "../../assets/isee.png";
import "../assets/vid.css";
import { ContactInfo, Footer, History, Welcome } from "../widgets";

const Dash: React.FC<{}> = () => {
  document.addEventListener("scroll", () => {
    const scrollHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPosition = document.documentElement.scrollTop;
    // Apply the factor here to reduce the effect of the scroll on the movement
    const scrollPercent = (scrollPosition / scrollHeight) * 0.5; // Factor of 0.5 reduces the movement

    const minStartingWidth = 50; // Minimum width percentage of the viewport width
    const maxStartingWidth = 50; // Maximum width percentage of the viewport width
    const dynamicWidthRange = 40;

    // Calculate widths based on modified scroll percent
    const leftTriangleWidth =
      minStartingWidth + dynamicWidthRange * scrollPercent;
    const rightTriangleWidth =
      maxStartingWidth - dynamicWidthRange * scrollPercent;

    const triangleLeft = document.getElementById("triangle-left");
    const triangleRight = document.getElementById("triangle-right");

    if (triangleLeft && triangleRight) {
      triangleLeft.style.width = `calc(${leftTriangleWidth}vw)`;
      triangleRight.style.width = `calc(${rightTriangleWidth}vw)`;
    }
  });
  const { theme } = useEloise();

  return (
    <Container
      fluid
      className="mx-auto">
      <Row className="g-0 my-0">
        <div className="fade-in">
          <Welcome />
          <History />

          <Row className="largeMargin">
            <Row>
              <Col
                lg={5}
                xs={10}
                className="mx-auto"
                style={{ borderBottom: theme.border }}>
                <Heading size={4}>Partnerships</Heading>
              </Col>
            </Row>
            <Row className="text-center">
              <Col lg={3}></Col>
              <Col
                lg={3}
                className="mx-auto">
                <a
                  href="https://www.dynonobel.com/"
                  target="_blank">
                  <img
                    src={dyno}
                    style={{ width: "75%", margin: "10% auto" }}
                  />
                </a>
              </Col>
              <Col
                lg={3}
                className="mx-auto">
                <a
                  href="https://isee.org/"
                  target="_blank">
                  <img
                    src={isee}
                    style={{ width: "95%", margin: "0 auto" }}
                  />
                </a>
              </Col>
              <Col lg={3}></Col>
            </Row>
          </Row>

          <ContactInfo />
          <Footer />
        </div>
      </Row>
    </Container>
  );
};

export default Dash;

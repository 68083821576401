import React from "react";
import { Col, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";

import annLogo from "../../assets/annLogoP.png";
import pepes from "../../assets/bulkTrucks2.png";
import newTruckWide from "../../assets/newTruckWide.png";
export const About: React.FC = () => {
  const { theme } = useEloise();
  return (
    <Row className="largeMargin">
      <Col
        lg={4}
        xs={{ span: 10, order: 1 }}
        className="mx-auto">
        <Row className="mb-2">
          <Col
            lg={11}
            xs={11}
            className="mx-auto text-center">
            <img
              src={annLogo}
              style={{ width: "75%" }}
            />
          </Col>
        </Row>
        <Row className="my-5">
          <Col
            lg={11}
            className="mx-auto mt-5">
            <img
              src={pepes}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </Col>
      <Col
        style={{
          borderLeft: `1px solid ${theme.colors[0]}`,
          borderBottom: `1px solid ${theme.colors[0]}`,
        }}
        lg={7}
        xs={{ span: 10, order: 0 }}
        className="mx-auto triangle-corner-col-opp px-5 pb-5">
        <Row className="mb-3">
          <Col
            lg={5}
            className="mx-auto"
            style={{ borderBottom: theme.border }}>
            <Heading size={4}>About Us</Heading>
          </Col>
        </Row>
        <p>
          In 1904, brothers Charles and Francis Davis purchased Wampum Hardware
          Company, a general hardware and explosive store in Wampum, PA. This
          rambling rural store was quick to become a household name, as well as
          a dependable resource for necessities like building materials, barbed
          wire fencing and dynamite. What started out as a business run by the
          two brothers is now a highly regarded force in the explosives
          industry.
        </p>{" "}
        <p>
          The company has grown with the times. It’s kept up with increasingly
          stringent safety regulations, and embraced the advent of new
          technologies. In the 1960s, under the fourth generation leadership of
          Jerry Davis and Paul Davis, Wampum decided to go beyond selling farm
          goods and cases of explosives. Looking to the future, the company
          plunged into the business of providing bulk explosives and complete
          blasting services to the coal mines and quarries of Western
          Pennsylvania and eastern Ohio and Indiana.
        </p>
        <p>
          {" "}
          Today, 90% of Wampum’s business is contract blasting – making it one
          of the largest blasting companies in North America in terms of shot
          service. WHC has over 200 employees, many with more than 20 years of
          company service. Wampum Hardware employs over 90 blasters, licensed in
          five states. WHC’s fleet of equipment continues to grow with their
          customer’s needs. Wampum Hardware crews are known for being safety &
          customer oriented, the employees and owners have huge hearts and
          always meet the customer’s service needs, while being capable of
          overcoming the most demanding blasting challenges.
        </p>
        <p>
          2024 is Wampum Hardware Co.’s 120th year anniversary and the future is
          bright, here’s to the first steps toward the next 120!
        </p>
        <img
          src={newTruckWide}
          style={{ width: "100%" }}
        />
      </Col>
    </Row>
  );
};
